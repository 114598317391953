@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src: url(../fonts/Montserrat-italic-100.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: url(../fonts/Montserrat-italic-200.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url(../fonts/Montserrat-italic-300.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url(../fonts/Montserrat-italic-400.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url(../fonts/Montserrat-italic-500.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url(../fonts/Montserrat-italic-600.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url(../fonts/Montserrat-italic-700.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: url(../fonts/Montserrat-italic-800.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: url(../fonts/Montserrat-italic-900.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/Montserrat-normal-100.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url(../fonts/Montserrat-normal-200.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/Montserrat-normal-300.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Montserrat-normal-400.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/Montserrat-normal-500.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/Montserrat-normal-600.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/Montserrat-normal-700.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url(../fonts/Montserrat-normal-800.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/Montserrat-normal-900.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url(../fonts/Poppins-italic-100.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url(../fonts/Poppins-italic-200.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url(../fonts/Poppins-italic-300.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url(../fonts/Poppins-italic-400.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url(../fonts/Poppins-italic-500.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url(../fonts/Poppins-italic-600.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url(../fonts/Poppins-italic-700.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url(../fonts/Poppins-italic-800.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url(../fonts/Poppins-italic-900.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/Poppins-normal-100.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url(../fonts/Poppins-normal-200.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/Poppins-normal-300.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Poppins-normal-400.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/Poppins-normal-500.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/Poppins-normal-600.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/Poppins-normal-700.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url(../fonts/Poppins-normal-800.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/Poppins-normal-900.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src: url(../fonts/Source_Sans_Pro-italic-200.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: url(../fonts/Source_Sans_Pro-italic-300.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url(../fonts/Source_Sans_Pro-italic-400.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: url(../fonts/Source_Sans_Pro-italic-600.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url(../fonts/Source_Sans_Pro-italic-700.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src: url(../fonts/Source_Sans_Pro-italic-900.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: url(../fonts/Source_Sans_Pro-normal-200.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/Source_Sans_Pro-normal-300.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Source_Sans_Pro-normal-400.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/Source_Sans_Pro-normal-600.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/Source_Sans_Pro-normal-700.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/Source_Sans_Pro-normal-900.woff) format("woff");
  unicode-range: U+0-10FFFF;
}
